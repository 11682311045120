import { Log } from './lib/rollbar'
import './lib/polyfill'
//import { isSSR } from './lib/ssr'
import "./scss/style.scss"
import { setTimeZoneCookies } from './lib/timezone'
import { whenDomReady } from './lib/when-dom-ready'

import Turbolinks from 'turbolinks'
if (!import.meta.env.SSR) {
    Turbolinks.start()
}


// if (!isSSR) {
//     import('turbolinks').then((t) => {
//         t.default.start()
//         renderPanels()
//     })
// }

const HANDLERS = {
    login: () => import('./panels/login'),
    signup: () => import('./panels/signup'),
    'app-header': () => import('./panels/app-header'),
    'reset-password': () => import('./panels/reset-password'),
    'homepage-header': () => import('./panels/homepage-header'),
    'find-account': () => import('./panels/find-account'),
    'request-reset': () => import('./panels/request-reset'),
    'complete-signup': () => import('./panels/complete-signup'),
    'project-tasks': () => import('./panels/project-tasks'),
    'time-entry-locations': () => import('./panels/time-entry-locations'),
}

let initialRender = true

const renderPanel = (panel: HTMLElement, args?: any) => {
    const getHandler = HANDLERS[panel.dataset.panelId]
    if (getHandler) {
        getHandler().then((handler: any) => {
            if (handler && handler.default) {
                handler.default({
                    ...panel.dataset,
                    panel,
                    initialRender,
                }, args)
                getHandler.rendered = true
                panel.dataset.isAttached = 'true'
                panel.classList.add('react-rendered-panel')
            } else {
                Log.critical(`handler ${panel.dataset.panelId} lacks default`)
            }
        })
    } else {
        Log.critical(`Found panel ${panel.dataset.panelId} but no handler was registered`)
    }
}

const renderPanels = async () => {
    document.querySelectorAll('[data-panel-id]').forEach(renderPanel)
}

const beforeNextVisit = () => {
    initialRender = false
    document.removeEventListener('turbolinks:visit', beforeNextVisit)
}

document.addEventListener('turbolinks:visit', beforeNextVisit)
document.addEventListener('turbolinks:load', renderPanels)

whenDomReady(() => {
    (window as any).RenderReactPanel = renderPanel
    setTimeZoneCookies()
})
